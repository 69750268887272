import { ClientT, EventsSettingsFn } from '@/config/types'

import nbcuDtcEvents from './nbcu-dtc'
import nowEvents from './now'
import nowGoldEvents from './now-gold'
import peacockEvents from './peacock'
import showmaxEvents from './showmax'
import skyEuropeEvents from './sky-europe'
import skyEuropeAndPeacockEvents from './sky-europe-and-peacock'
import skyshowtimeEvents from './skyshowtime'

export const events: Record<ClientT, EventsSettingsFn> = {
  peacock: peacockEvents,
  skyshowtime: skyshowtimeEvents,
  'nbcu-dtc': nbcuDtcEvents,
  'sky-europe-and-peacock': skyEuropeAndPeacockEvents,
  'sky-europe': skyEuropeEvents,
  now: nowEvents,
  showmax: showmaxEvents,
  'now-gold': nowGoldEvents,
}

export default events
