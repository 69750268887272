import { RiTimerFlashFill, RiTimerFlashLine } from 'react-icons/ri'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'

import commonConvivaConfig from './common/conviva'

const averageVideoStartupTime: MetricSettingsFn = ({
  variants,
}): MetricSettingsI => {
  const convivaConfig = commonConvivaConfig({ variants })

  return {
    ...convivaConfig,
    value: 'video-startup-time',
    label: 'Video Startup Time (s)',
    shortLabel: 'Video Start Time',
    dataKey: 'videoStartupTime',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'spline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 1,
        count: 5,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: RiTimerFlashLine,
    solidIcon: RiTimerFlashFill,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', {
        style: 'unit',
        unit: 'second',
        unitDisplay: 'narrow',
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      }).format(value),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', {
        style: 'unit',
        unit: 'second',
        unitDisplay: 'narrow',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value),
    info: 'Video Startup Time is the number of seconds between when the Conviva monitoring starts and the first frame of video is played.',
    aggregationType: 'average',
    ragThresholds: {
      green: 0,
      amber: 5,
      red: 6,
    },
    defaultConfig: {
      ...convivaConfig.defaultConfig,
      metric: 'video-startup-time',
    },
  }
}

export default averageVideoStartupTime
