import moment, { Moment } from 'moment-timezone'
import { HiOutlineCog, HiOutlineGlobeAlt } from 'react-icons/hi'

import { CLIENT_DEFAULT_TIMEZONES } from '@/config/constants'
import {
  OPERATING_SYSTEM,
  TERRITORY,
  nowTerritoryOptions,
} from '@/config/formOptions'

import { FormI, HomeSettingsI, SelectOptionI } from '../../types'

export const qualityMetrics = [
  'average-bitrate',
  'connection-induced-rebuffering-ratio',
  'video-startup-time',
  'video-start-failures-percentage',
  'video-playback-failures-percentage',
  'exits-before-video-start-percentage',
]

export const filterOptions: FormI[] = [
  {
    value: 'territory',
    type: 'select',
    multi: true,
    options: [TERRITORY['all'], ...nowTerritoryOptions],
  },
  {
    value: 'operating-system',
    type: 'select',
    multi: true,
    // Options copied from /tags endpoint response for NOW client
    options: [
      OPERATING_SYSTEM['all'],
      OPERATING_SYSTEM['amazon-fire'],
      OPERATING_SYSTEM['android'],
      OPERATING_SYSTEM['android-tv'],
      OPERATING_SYSTEM['chromecast'],
      OPERATING_SYSTEM['desktop'],
      OPERATING_SYSTEM['ios'],
      OPERATING_SYSTEM['lg-tv'],
      OPERATING_SYSTEM['linux'],
      OPERATING_SYSTEM['playstation'],
      OPERATING_SYSTEM['roku'],
      OPERATING_SYSTEM['samsung-tv'],
      OPERATING_SYSTEM['tv-os'],
      OPERATING_SYSTEM['vidaa'],
      OPERATING_SYSTEM['web'],
      OPERATING_SYSTEM['xbox'],
      OPERATING_SYSTEM['youview'],
    ],
  },
]

export const splitByOptions: SelectOptionI[] = [
  {
    label: 'Split by Territory',
    icon: HiOutlineGlobeAlt,
    value: 'territory',
  },
  {
    label: 'Split by Operating System',
    icon: HiOutlineCog,
    value: 'operating-system',
  },
]

export const defaultConfig = {
  'split-by': 'territory',
  territory: [],
  proposition: ['now-gold'],
  start: moment()
    .tz(CLIENT_DEFAULT_TIMEZONES['now'])
    .startOf('day')
    .subtract(1, 'day')
    .utc()
    .format(),
  end: moment()
    .tz(CLIENT_DEFAULT_TIMEZONES['now'])
    .endOf('day')
    .subtract(1, 'day')
    .utc()
    .format(),
}

const homeSettings: HomeSettingsI = {
  qualityMetrics,
  filterOptions,
  splitByOptions,
  defaultConfig,
  realtime: {
    topEvents: {
      streamTypeOptions: ['sle', 'linear'],
      defaultStreamType: 'sle',
      label: 'Top Live Events',
    },
    getDateRange: (): { start: Moment; end: Moment } => {
      const now = moment()
        .clone()
        .tz(CLIENT_DEFAULT_TIMEZONES['now'])
        .subtract(1, 'minute')
        .startOf('minute')

      const start = now.clone().subtract(30, 'minutes')
      const end = now.clone()

      return { start, end }
    },
  },
  topEvents: {
    streamTypeOptions: ['sle', 'linear', 'vod'],
    defaultStreamType: 'sle',
  },

  minutesAndUniques: {
    enabled: false,
    config: {
      'exclude-proposition': ['skyshowtime'],
    },
  },
  passActivationsAndCancellations: {
    enabled: false,
  },
  quality: {
    enabled: true,
  },
  signInsAndSignUps: {
    enabled: true,
  },
  weeklyViewingReport: {
    enabled: true,
  },
}

export default Object.freeze(homeSettings)
