import { MdNetworkCheck } from 'react-icons/md'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import formatDataSize from '@/utils/formatDataSize'

import commonConvivaConfig from './common/conviva'

const averageBitrate: MetricSettingsFn = ({ variants }): MetricSettingsI => {
  const convivaConfig = commonConvivaConfig({ variants })
  return {
    ...convivaConfig,
    value: 'average-bitrate',
    label: 'Average Bitrate',
    shortLabel: 'Avg. Bitrate',
    dataKey: 'averageBitrate',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'spline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 5,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: MdNetworkCheck,
    solidIcon: MdNetworkCheck,
    yAxisFormat: (value: number): string => {
      return formatDataSize({ value, unit: 'bit', perSecond: true })
    },
    valueFormat: (value: number): string => {
      return formatDataSize({ value, unit: 'bit', perSecond: true })
    },
    info: 'Average Bitrate shows the average bitrate at which content was delivered across the entire audience in a given interval.',
    aggregationType: 'average',
    // ragThresholds: {
    //   red: 0,
    //   amber: 4000000,
    //   green: 5000000,
    // },
    defaultConfig: {
      ...convivaConfig.defaultConfig,
      metric: 'average-bitrate',
    },
  }
}

export default averageBitrate
