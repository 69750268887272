import { ClientT, MetricSettingsFn } from '../types'
import nbcuDtcMetrics from './nbcu-dtc'
import nowMetrics from './now'
import nowGoldMetrics from './now-gold'
import peacockMetrics from './peacock'
import showmaxMetrics from './showmax'
import skyEuropeMetrics from './sky-europe'
import skyEuropeAndPeacockMetrics from './sky-europe-and-peacock'
import skyshowtimeMetrics from './skyshowtime'

type ClientMetricSettingsI = {
  [key: string]: MetricSettingsFn
}

export const metrics: Record<ClientT, ClientMetricSettingsI> = {
  peacock: peacockMetrics,
  skyshowtime: skyshowtimeMetrics,
  'nbcu-dtc': nbcuDtcMetrics,
  'sky-europe-and-peacock': skyEuropeAndPeacockMetrics,
  'sky-europe': skyEuropeMetrics,
  now: nowMetrics,
  showmax: showmaxMetrics,
  'now-gold': nowGoldMetrics,
}

export default metrics
